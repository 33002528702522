@import 'styles/_mixins';

.block {
  position: relative;
}

.current {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;

  @include mediaMinS {
    padding: 15px 20px;
    font-size: 18px;
  }
}

.currentLabel {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.arrow {
  transition: transform 0.3s ease-in-out;

  .opened & {
    transform: rotate(-180deg);
  }
}

.list {
  position: absolute;
  top: 33px;
  padding: 10px 20px 10px 15px;
  margin: 0;
  width: 100%;
  display: none;
  list-style: none;
  border: 1px solid #e5e5e5;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;

  .opened & {
    display: block;
  }

  @include mediaMinS {
    top: 49px;
    padding: 15px 40px 15px 20px;
  }
}

.listItem {
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease;

  @include mediaMinS {
    font-size: 18px;
    margin-bottom: 15px;
  }

  &:hover {
    color: #5a9ce7;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.listItemSelected {
    color: #5a9ce7;
  }
}
