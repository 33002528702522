@import 'styles/_mixins';

.block {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: 0.3s ease;
  text-decoration: none;
  border-radius: 12px;
  min-width: 160px;

  // sizes
  &.small {
    min-height: 45px;
    padding: 9px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
  }

  &.medium {
    min-height: 70px;
    padding: 22px 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  // types
  &.outline {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
  }

  &:hover:not(.outline) {
    filter: brightness(120%) saturate(120%);
  }

  &.fluid {
    width: 100%;
  }
}
