.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  overflow: auto;

  &.vertical {
    flex-direction: column;
  }
}

.listItem.listItem {
  margin: 0 20px 0 0;
  padding: 8px 19px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  font-size: 13px;
  font-weight: normal;
  line-height: 22px;
  color: #181818;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;

  &.active {
    background-color: #426fff;
    color: #ffffff;
  }

  .vertical & {
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  a {
    display: block;
    padding: 8px 19px;
  }
}
