.block {
  padding-top: 100px;
  margin-top: -100px;
  -webkit-background-clip: content-box;
  background-clip: content-box;
}
.title {
  height: 210px;
  background-image: url(bg.png);
  background-repeat: no-repeat;
  background-size: 207px 210px;
  background-position: 76px center;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    background: #f28f99;
    border-radius: 15px;
    padding: 20px 32px;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;
    color: #fff;

    @media (min-width: 769px) {
      font-size: 46px;
      line-height: 72px;
      padding: 8px 32px;
    }
  }
}

.stages {
  margin-top: 20px;

  .stageItem {
    display: block;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 14px 20px 14px 20px;
    cursor: pointer;

    @media (min-width: 769px) {
      padding: 18px 20px 18px 50px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &.ended {
      opacity: 0.4;
    }

    .header {
      display: flex;
      justify-content: space-between;

      h3 {
        font-size: 22px;
        line-height: 36px;
        margin: 0;

        @media (min-width: 769px) {
          font-size: 30px;
        }
      }
    }

    .description {
      margin: 30px 0 0;
      line-height: 26px;
    }

    ul {
      list-style: none;
      margin: 30px 0;
      padding: 0;
      max-width: 80%;
      font-weight: 300;

      li {
        position: relative;
        padding-left: 25px;

        > a {
          color: #579aea;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ed694a;
        }

        & + li {
          margin-top: 20px;
        }
      }
    }
  }
}
