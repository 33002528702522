@import 'styles/_mixins';

.container {
  position: relative;

  max-width: 1350px;
  width: 100%;

  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &:hover {
    .background > div {
      transform: scale(1.2);
    }
  }
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;

  & > div {
    transition: transform 0.3s ease-in-out;
  }

  video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    background: linear-gradient(
      to bottom,
      rgba(153, 153, 153, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
}

.children {
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  color: #fff;
}

.small {
  --border-radius-desktop: 36px;
  --border-radius-mobile: 16px;

  padding: 0;
  border-radius: var(--border-radius-mobile);

  @include mediaMinL {
    border-radius: var(--border-radius-desktop);
  }

  .container {
    min-height: 260px;
    padding: 14px 14px 24px;
    border-radius: var(--border-radius-mobile);

    @include mediaMinL {
      padding: 24px 30px 36px;
      border-radius: var(--border-radius-desktop);
    }
  }

  .background {
    border-radius: var(--border-radius-mobile);

    @include mediaMinL {
      border-radius: var(--border-radius-desktop);
    }

    &:before {
      border-radius: var(--border-radius-mobile);

      @include mediaMinL {
        border-radius: var(--border-radius-desktop);
      }
    }
  }

  .title {
    font-size: 20px;
    line-height: 24px;

    @include mediaMinL {
      font-size: 26px;
      line-height: 32px;
    }
  }

  .buttons {
    margin-top: 2vh;

    .buttonsInner {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;

      & > *:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.medium {
  --border-radius-desktop: 40px;
  --border-radius-mobile: 16px;

  padding: 0;
  border-radius: var(--border-radius-mobile);

  @include mediaMinL {
    border-radius: var(--border-radius-desktop);
  }

  .container {
    height: 44vw;
    max-height: 630px;
    min-height: 300px;
    padding: 14px 14px 24px;

    @include mediaMinL {
      padding: 36px 36px 34px;
    }
  }

  .background {
    border-radius: var(--border-radius-mobile);

    @include mediaMinL {
      border-radius: var(--border-radius-desktop);
    }

    &:before {
      border-radius: var(--border-radius-mobile);

      @include mediaMinL {
        border-radius: var(--border-radius-desktop);
      }
    }
  }

  .title {
    font-size: 26px;
    line-height: 22px;

    @include mediaMinL {
      font-size: 28px;
      line-height: 22px;
    }
  }
}

.large {
  --border-radius-desktop: 40px;
  --border-radius-mobile: 16px;

  border-radius: var(--border-radius-mobile);

  @include mediaMinL {
    border-radius: var(--border-radius-desktop);
  }

  .container {
    height: 44vw;
    max-height: 700px;
    min-height: 570px;
    padding: 14px 14px 24px;

    @include mediaMinL {
      padding: 34px 50px 82px;
    }
  }

  .background {
    border-radius: var(--border-radius-mobile);

    @include mediaMinL {
      border-radius: var(--border-radius-desktop);
    }

    &:before {
      @include mediaMinL {
        border-radius: var(--border-radius-desktop);
      }
    }
  }

  .title {
    font-size: 24px;
    line-height: 26px;

    @include mediaMinL {
      font-size: 44px;
      line-height: 50px;
    }
  }

  .description {
    font-size: 13px;
    line-height: 19px;
    margin-top: 14px;
    font-weight: 300;

    max-height: 140px;
    overflow-y: auto;

    @include mediaMinM {
      font-size: 19px;
      line-height: 27px;
      margin-top: 20px;
    }
  }

  .buttons {
    margin-top: 40px;

    @include mediaMinM {
      margin-top: 40px;
    }

    @include mediaMinL {
      justify-self: start;
    }

    .buttonsInner {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.intro {
  --border-radius-desktop: 40px;

  @include mediaMinL {
    border-radius: var(--border-radius-desktop);
    padding: 0 15px;
  }

  .container {
    height: 44vw;
    max-height: 700px;
    min-height: 570px;
    padding: 14px 14px 24px;

    @include mediaMinL {
      padding: 34px 50px 82px;
    }
  }

  .background {
    @include mediaMinL {
      border-radius: var(--border-radius-desktop);
    }

    &:before {
      @include mediaMinL {
        border-radius: var(--border-radius-desktop);
      }
    }
  }

  .title {
    font-size: 24px;
    line-height: 26px;

    @include mediaMinL {
      font-size: 44px;
      line-height: 50px;
    }
  }

  .description {
    font-size: 13px;
    line-height: 19px;
    margin-top: 14px;
    font-weight: 300;

    max-height: 140px;
    overflow-y: auto;

    @include mediaMinM {
      font-size: 19px;
      line-height: 27px;
      margin-top: 20px;
    }
  }

  .buttons {
    @include mediaMinM {
      margin-top: 40px;
    }

    @include mediaMinL {
      justify-self: start;
    }

    .buttonsInner {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.top .children {
  justify-content: flex-start;
}

.center .children {
  justify-content: center;
}

.bottom .children {
  justify-content: flex-end;
}

.topWidgets {
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1;
  color: #fff;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    display: flex;
    align-items: center;
  }
}

.content {
  width: 100%;
  margin: auto 0 0;
  padding-top: 20px;
  z-index: 1;
  display: grid;
  grid-template-areas:
    'title'
    'description'
    'widget'
    'buttons';

  @include mediaMinM {
    grid-template-areas:
      'title widget'
      'description widget'
      'buttons widget';
  }

  &.fullHeight {
    flex: 1;
  }

  &.withoutWidget {
    display: flex;
    flex-direction: column;
  }

  .right {
    margin-top: 40px;
    grid-area: widget;
    color: #fff;

    @include mediaMinM {
      padding-left: 40px;
      margin: auto 0 0 auto;
    }
  }
}

.buttons {
  grid-area: buttons;
  height: 58px;
  overflow: hidden;

  .buttonsInner {
    & > *:first-child {
      flex: 1;

      @include mediaMinM {
        flex: unset;
      }
    }
  }
}

.title {
  grid-area: title;
}

.description {
  grid-area: description;
}

.clicked {
  cursor: pointer;
}
