@import 'styles/mixins';

.slickPrev,
.slickNext {
  height: 45px;
  width: 45px;
  z-index: 20;
  top: 50%;
  transform: translateY(-50%);

  border: 1px solid #e5e5e5;
  border-radius: 50%;
  background: transparent;
}

.slickPrev {
  left: 10px;

  @include mediaMinXl {
    left: -40px;
  }
}
.slickNext {
  right: 10px;

  @include mediaMinXl {
    right: -40px;
  }
}

.slickPrev:before,
.slickNext:before {
  display: inline-block;
  content: '';
  width: 10px;
  height: 17px;

  background: url('/icons/slider-arrow.svg') 100% no-repeat;
}

.slickNext:before {
  transform: rotate(180deg) translateX(-30%);
}
