.input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;

  &:checked + label .icon {
    background: #426fff;
    svg {
      fill: #fff;
    }
  }
}

.label {
  display: flex;
  cursor: pointer;
  font-weight: 350;
  font-size: 12px;
  line-height: 18px;
  color: #666c77;

  a,
  a:visited {
    color: #426fff;
  }
}

.icon {
  background: transparent;
  border: 1px solid #426fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  min-width: 16px;
  transition: all 0.2s ease-in-out;
  margin-right: 12px;

  svg {
    fill: transparent;
  }
}
