.block {
  position: relative;
  margin-bottom: 10px;
}

.label {
  display: block;
  margin-bottom: 10px;
}

.field {
  position: relative;
}

.input {
  min-width: 210px;
  height: 58px;
  padding: 0 20px;
  border: 1px solid transparent;
  outline: none;
  background-color: #f5f6f7;
  border-radius: 12px;
  font-size: 14px;
  line-height: 22px;
  color: var(--default-text-color);
  transition: border 0.3s ease;

  &:focus {
    border-color: var(--default-primary-color);
  }

  &.error {
    border-color: var(--color-danger);
  }

  &::placeholder {
    color: #c4c4c4;
    opacity: 1; /* firefox */
  }
}

.fluid {
  width: 100%;
}

.eye {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

.eyeClosed {
  margin-top: 3px;
}
