@import 'styles/_mixins';

.text {
  font-style: normal;
  font-weight: 300;
  color: var(--default-text-color);
}

.large {
  font-size: 13px;
  line-height: 19px;

  @include mediaMinL {
    font-size: 19px;
    line-height: 27px;
  }
}

.normal {
  font-size: 17px;
  line-height: 26px;
}

.small_plus {
  font-size: 15px;
  line-height: 26px;
}

.small {
  font-size: 13px;
  line-height: 19px;
}

.small_x {
  font-size: 12px;
  line-height: 18px;
}

.a {
  color: var(--default-link-color);

  &:hover {
    color: var(--active-link-color);
    cursor: pointer;
  }
}

.blue {
  color: var(--default-primary-color);
}

.danger {
  color: var(--color-danger);
}

.white {
  color: #ffffff;
}

.light {
  font-weight: 300;
}

.bold {
  font-weight: bold;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
