@import 'styles/_mixins';

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  overflow-y: auto;
}

.body {
  position: relative;
  padding: 15px;
  max-width: 800px;
  width: 100%;
  margin: 100px 0;

  border-radius: 20px;
  background-color: #ffffff;

  @include mediaMinM {
    padding: 40px;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  line-height: 40px;
  padding: 0 12px;

  color: #666c77;
  cursor: pointer;
  z-index: 101;
}
